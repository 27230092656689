import revive_payload_client_vs2MOEA5OW from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7LLLaEUHPp from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jNY9GsCni7 from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IHAFEi7gRA from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@6.2.0_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bhB1PyK2mV from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QMpbximBZX from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jbUSUMe3SH from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WI9ZFVO3Xg from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_dEmzu6QVn2 from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_hYfUukyPyA from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.34.9_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_fUEvfpaGmZ from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_c3yQH845SH from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@6.5.3_webpack@5.98.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import _00_cookie_control_client_4SYQXZkRaI from "/app/app/plugins/00.cookie-control.client.js";
import _00_helper_DkpPUpYYYH from "/app/app/plugins/00.helper.js";
import _01_gtm_client_8oykEgeZko from "/app/app/plugins/01.gtm.client.js";
import _01_mutiny_client_Spnmhp0rKe from "/app/app/plugins/01.mutiny.client.js";
import _01_segment_client_WOHZofdYJ1 from "/app/app/plugins/01.segment.client.js";
import _02_chilipiper_client_yXTLdlkU5G from "/app/app/plugins/02.chilipiper.client.js";
import _02_hls_client_FpFxOExKPh from "/app/app/plugins/02.hls.client.js";
import _02_hubspot_forms_client_nwKIx4clpd from "/app/app/plugins/02.hubspot-forms.client.js";
import _02_hubspot_x_jquery_client_8TKEgAofvO from "/app/app/plugins/02.hubspot-x-jquery.client.js";
import _02_lottie_player_client_xharNIIAET from "/app/app/plugins/02.lottie-player.client.js";
import _02_partnerstack_client_Bo7v8mdp6R from "/app/app/plugins/02.partnerstack.client.js";
export default [
  revive_payload_client_vs2MOEA5OW,
  unhead_7LLLaEUHPp,
  router_jNY9GsCni7,
  _0_siteConfig_IHAFEi7gRA,
  payload_client_bhB1PyK2mV,
  navigation_repaint_client_QMpbximBZX,
  check_outdated_build_client_jbUSUMe3SH,
  chunk_reload_client_WI9ZFVO3Xg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dEmzu6QVn2,
  i18n_hYfUukyPyA,
  plugin_fUEvfpaGmZ,
  plugin_c3yQH845SH,
  _00_cookie_control_client_4SYQXZkRaI,
  _00_helper_DkpPUpYYYH,
  _01_gtm_client_8oykEgeZko,
  _01_mutiny_client_Spnmhp0rKe,
  _01_segment_client_WOHZofdYJ1,
  _02_chilipiper_client_yXTLdlkU5G,
  _02_hls_client_FpFxOExKPh,
  _02_hubspot_forms_client_nwKIx4clpd,
  _02_hubspot_x_jquery_client_8TKEgAofvO,
  _02_lottie_player_client_xharNIIAET,
  _02_partnerstack_client_Bo7v8mdp6R
]