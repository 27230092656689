<template>
  <nav
    class="navigation"
    :class="[
      props.theme,
      { opened: menuIsOpened },
    ]"
  >
    <!-- Logo -->
    <AtomLogo :theme="menuIsOpened ? 'light' : props.theme" />

    <!-- Menu -->
    <UiComponentWrapper
      class="menu"
      :style="{ '--height': `${menuHeight}px` }"
    >
      <!-- Menu > Items -->
      <UiComponentWrapper class="items">
        <!-- Menu > Items > Products -->
        <UiComponentWrapper
          class="item"
          :class="{ active: activePaneIndex === 0 }"
          @click="screenWidth < 1328 ? setPaneIndex(0) : null"
          @mouseenter="screenWidth >= 1328 ? setPaneIndex(0) : null"
          @mouseleave="screenWidth >= 1328 ? resetPaneIndex() : null"
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.productTitle }}
          </UiComponentParagraph>
          <UiComponentIcon type="chevron-down" />
          <UiComponentWrapper class="pane">
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="content product">
                <UiComponentWrapper class="main">
                  <UiComponentParagraph class="title">
                    {{ data.navigation.productSubtitle }}
                  </UiComponentParagraph>
                  <UiComponentWrapper class="list">
                    <AtomAnchor
                      v-for="product in data.products.filter(({ title }) => !!title)"
                      :key="product.id"
                      class="list-item"
                      :to="getRouteOfProductItem(product)"
                      @click="
                        closeMobileMenu();
                        resetPaneIndex();
                        track('Navigation bar clicked', {
                          category: 'Button',
                          label: getRouteOfProductItem(product),
                          locale,
                          position: 'Product',
                        })
                      "
                    >
                      <UiComponentIcon :type="product.icon?.type" />
                      <UiComponentParagraph class="title">
                        {{ product.title }}
                      </UiComponentParagraph>
                      <UiComponentParagraph class="description">
                        {{ product.text }}
                      </UiComponentParagraph>
                    </AtomAnchor>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper
                  class="aside"
                >
                  <AtomAnchor
                    class="features"
                    :to="localePath('features')"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: localePath('features'),
                        locale,
                        position: 'Product',
                      })
                    "
                  >
                    <UiComponentParagraph class="title">
                      {{ data.features.title }}
                    </UiComponentParagraph>
                    <UiComponentParagraph class="description">
                      {{ data.features.text }}
                    </UiComponentParagraph>
                    <UiComponentImage
                      v-if="data.features.illustration"
                      :alt="data.features.illustration.alt"
                      class="illustration"
                      :expected-size="208"
                      :height="data.features.illustration.height"
                      orientation="portrait"
                      :source="data.features.illustration.url"
                      :width="data.features.illustration.width"
                    />
                  </AtomAnchor>
                  <AtomAnchor
                    class="integrations"
                    :force-reload="locale === 'de'"
                    :to="locale === 'de' ? '/integrations' : localePath('integrations')"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: localePath('integrations'),
                        locale,
                        position: 'Product',
                      })
                    "
                  >
                    <UiComponentParagraph class="title">
                      {{ data.integrations.title }}
                    </UiComponentParagraph>
                    <UiComponentParagraph class="description">
                      {{ data.integrations.text }}
                    </UiComponentParagraph>
                    <UiComponentWrapper class="highlighted">
                      <UiComponentWrapper
                        v-for="integration in data.integrations.highlighted"
                        :key="integration.id"
                        class="integration"
                      >
                        <UiComponentImage
                          :alt="integration.logo.alt"
                          class="logo"
                          :expected-size="24"
                          :height="integration.logo.height"
                          :source="integration.logo.url"
                          :width="integration.logo.width"
                        />
                        <UiComponentParagraph>{{ integration.name }}</UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <AtomButton>
                      {{ data.navigation.allIntegrations }}
                      <UiComponentIcon type="arrow-right" />
                    </AtomButton>
                  </AtomAnchor>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="more">
                <AtomButton
                  action="Navigation bar clicked"
                  :attributes="{
                    position: 'Product',
                  }"
                  :label="data.componentOnDemandDemo.link"
                  :to="data.componentOnDemandDemo.link"
                  type="secondary"
                >
                  <UiComponentIcon type="on-demand" />
                  {{ data.componentGlobal.watchAnOnDemandDemo }}
                </AtomButton>
                <AtomButton
                  :action="requestDemoCallToAction.action"
                  :attributes="{
                    position: 'Product',
                  }"
                  label="Navigation bar CTA"
                  :to="requestDemoCallToAction.to"
                  type="secondary"
                >
                  <UiComponentIcon type="phone" />
                  {{ data.componentGlobal.contactSales }}
                </AtomButton>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <!-- Menu > Items > Solutions -->
        <UiComponentWrapper
          class="item"
          :class="{ active: activePaneIndex === 1 }"
          @click="screenWidth < 1328 ? setPaneIndex(1) : null"
          @mouseenter="screenWidth >= 1328 ? setPaneIndex(1) : null"
          @mouseleave="screenWidth >= 1328 ? resetPaneIndex() : null"
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.solutionsTitle }}
          </UiComponentParagraph>
          <UiComponentIcon type="chevron-down" />
          <UiComponentWrapper class="pane">
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="content solutions">
                <UiComponentWrapper class="main">
                  <UiComponentParagraph class="title">
                    {{ data.navigation.solutionsByIndustries }}
                  </UiComponentParagraph>
                  <UiComponentWrapper class="list highlight">
                    <AtomAnchor
                      v-for="industry in data.industries.slice(0, 2)"
                      :key="industry.id"
                      class="list-item"
                      :class="industry.theme.color"
                      :to="
                        localePath({
                          name: 'industries-slug',
                          params: {
                            slug: industry.slug,
                          },
                        })
                      "
                      @click="
                        closeMobileMenu();
                        resetPaneIndex();
                        track('Navigation bar clicked', {
                          category: 'Button',
                          label: localePath({
                            name: 'industries-slug',
                            params: {
                              slug: industry.slug,
                            },
                          }),
                          locale,
                          position: 'Solutions',
                        })
                      "
                    >
                      <UiComponentIcon :type="industry.icon.type" />
                      <UiComponentParagraph>{{ industry.title }}</UiComponentParagraph>
                      <UiComponentParagraph>{{ industry.text }}</UiComponentParagraph>
                      <UiComponentImage
                        v-if="industry.illustration"
                        :alt="industry.illustration.alt"
                        class="illustration"
                        :expected-size="267"
                        :height="industry.illustration.height"
                        :source="industry.illustration.url"
                        :width="industry.illustration.width"
                      />
                    </AtomAnchor>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="list classic">
                    <AtomAnchor
                      v-for="industry in data.industries.slice(2, 5)"
                      :key="industry.id"
                      class="list-item"
                      :to="
                        localePath({
                          name: 'industries-slug',
                          params: {
                            slug: industry.slug,
                          },
                        })
                      "
                      @click="
                        closeMobileMenu();
                        resetPaneIndex();
                        track('Navigation bar clicked', {
                          category: 'Button',
                          label: localePath({
                            name: 'industries-slug',
                            params: {
                              slug: industry.slug,
                            },
                          }),
                          locale,
                          position: 'Solutions',
                        })
                      "
                    >
                      <UiComponentIcon :type="industry.icon.type" />
                      <UiComponentParagraph>{{ industry.title }}</UiComponentParagraph>
                    </AtomAnchor>
                  </UiComponentWrapper>
                  <AtomButton
                    action="Navigation bar clicked"
                    :attributes="{
                      position: 'Solutions',
                    }"
                    :label="localePath('industries')"
                    :to="localePath('industries')"
                    type="secondary"
                  >
                    {{ data.navigation.allIndustries }}
                    <UiComponentIcon type="arrow-right" />
                  </AtomButton>
                </UiComponentWrapper>
                <UiComponentWrapper class="aside">
                  <UiComponentWrapper class="use-cases">
                    <UiComponentParagraph class="title">
                      {{ data.navigation.solutionsByUseCases }}
                    </UiComponentParagraph>
                    <UiComponentWrapper class="list">
                      <AtomAnchor
                        v-for="useCase in data.useCases.slice(0, 6)"
                        :key="useCase.id"
                        class="list-item"
                        :to="
                          localePath({
                            name: 'use-cases-slug',
                            params: {
                              slug: useCase.slug,
                            },
                          })
                        "
                        @click="
                          closeMobileMenu();
                          resetPaneIndex();
                          track('Navigation bar clicked', {
                            category: 'Button',
                            label: localePath({
                              name: 'use-cases-slug',
                              params: {
                                slug: useCase.slug,
                              },
                            }),
                            locale,
                            position: 'Solutions',
                          })
                        "
                      >
                        <UiComponentIcon :type="useCase.icon.type" />
                        <UiComponentParagraph>{{ useCase.title }}</UiComponentParagraph>
                      </AtomAnchor>
                    </UiComponentWrapper>
                    <AtomButton
                      action="Navigation bar clicked"
                      :attributes="{
                        position: 'Solutions',
                      }"
                      :label="localePath('use-cases')"
                      :to="localePath('use-cases')"
                      type="secondary"
                    >
                      {{ data.navigation.allUseCases }}
                      <UiComponentIcon type="arrow-right" />
                    </AtomButton>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="teams">
                    <UiComponentParagraph class="title">
                      {{ data.navigation.solutionsByTeams }}
                    </UiComponentParagraph>
                    <UiComponentWrapper class="list">
                      <AtomAnchor
                        v-for="team in data.teams"
                        :key="team.id"
                        class="list-item"
                        :to="
                          localePath({
                            name: 'teams-slug',
                            params: {
                              slug: team.slug,
                            },
                          })
                        "
                        @click="
                          closeMobileMenu();
                          resetPaneIndex();
                          track('Navigation bar clicked', {
                            category: 'Button',
                            label: localePath({
                              name: 'teams-slug',
                              params: {
                                slug: team.slug,
                              },
                            }),
                            locale,
                            position: 'Solutions',
                          })
                        "
                      >
                        <UiComponentIcon :type="team.icon.type" />
                        <UiComponentParagraph>{{ team.title }}</UiComponentParagraph>
                      </AtomAnchor>
                    </UiComponentWrapper>
                    <AtomButton
                      action="Navigation bar clicked"
                      :attributes="{
                        position: 'Solutions',
                      }"
                      :label="localePath('teams')"
                      :to="localePath('teams')"
                      type="secondary"
                    >
                      {{ data.navigation.allTeams }}
                      <UiComponentIcon type="arrow-right" />
                    </AtomButton>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="more">
                <AtomButton
                  action="Navigation bar clicked"
                  :attributes="{
                    position: 'Solutions',
                  }"
                  :label="data.componentOnDemandDemo.link"
                  :to="data.componentOnDemandDemo.link"
                  type="secondary"
                >
                  <UiComponentIcon type="on-demand" />
                  {{ data.componentGlobal.watchAnOnDemandDemo }}
                </AtomButton>
                <AtomButton
                  :action="requestDemoCallToAction.action"
                  :attributes="{
                    position: 'Solutions',
                  }"
                  label="Navigation bar CTA"
                  :to="requestDemoCallToAction.to"
                  type="secondary"
                >
                  <UiComponentIcon type="phone" />
                  {{ data.componentGlobal.contactSales }}
                </AtomButton>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <!-- Menu > Items > Pricing -->
        <AtomAnchor
          class="item"
          :to="localePath('pricing')"
          @click="
            track('Navigation bar clicked', {
              category: 'Button',
              label: localePath('pricing'),
              locale,
              position: 'Pricing',
            })
          "
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.pricingTitle }}
          </UiComponentParagraph>
        </AtomAnchor>
        <!-- Menu > Items > Enterprise -->
        <UiComponentWrapper
          class="item"
          :class="{ active: activePaneIndex === 3 }"
          @click="screenWidth < 1328 ? setPaneIndex(3) : null"
          @mouseenter="screenWidth >= 1328 ? setPaneIndex(3) : null"
          @mouseleave="screenWidth >= 1328 ? resetPaneIndex() : null"
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.enterpriseTitle }}
          </UiComponentParagraph>
          <UiComponentIcon type="chevron-down" />
          <UiComponentWrapper class="pane">
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="content enterprise">
                <UiComponentWrapper class="main">
                  <AtomAnchor
                    class="card"
                    :to="localePath('enterprise')"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: localePath('enterprise'),
                        locale,
                        position: 'Enterprise',
                      })
                    "
                  >
                    <UiComponentIcon :type="data.enterprise.icon.type" />
                    <UiComponentParagraph>{{ data.enterprise.title }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ data.enterprise.text }}</UiComponentParagraph>
                    <UiComponentButton type="text">
                      {{ data.componentGlobal.learnMore }}
                      <UiComponentIcon type="arrow-right" />
                    </UiComponentButton>
                    <UiComponentWrapper class="illustration">
                      <UiComponentImage
                        v-if="data.enterprise.illustration"
                        :alt="data.enterprise.illustration.alt"
                        :expected-size="343"
                        :height="data.enterprise.illustration.height"
                        :source="data.enterprise.illustration.url"
                        :width="data.enterprise.illustration.width"
                      />
                    </UiComponentWrapper>
                  </AtomAnchor>
                </UiComponentWrapper>
                <UiComponentWrapper class="aside">
                  <AtomAnchor
                    class="card vip"
                    :to="localePath('customer-service')"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: localePath('customer-service'),
                        locale,
                        position: 'Enterprise',
                      })
                    "
                  >
                    <UiComponentIcon :type="data.customerService.icon.type" />
                    <UiComponentParagraph>
                      {{ data.customerService.title }}
                    </UiComponentParagraph>
                    <UiComponentParagraph>{{ data.customerService.text }}</UiComponentParagraph>
                  </AtomAnchor>
                  <AtomAnchor
                    class="card security"
                    target="_blank"
                    to="https://security.livestorm.co/"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: 'https://security.livestorm.co/',
                        locale,
                        position: 'Enterprise',
                      })
                    "
                  >
                    <UiComponentIcon :type="data.navigation.enterpriseSecurityIcon.type" />
                    <UiComponentParagraph>
                      {{ data.navigation.enterpriseSecurityTitle }}
                      <UiComponentIcon type="external-link" />
                    </UiComponentParagraph>
                    <UiComponentParagraph>{{ data.navigation.enterpriseSecurityText }}</UiComponentParagraph>
                    <UiComponentWrapper class="badge">
                      <UiComponentImage
                        v-if="data.navigation.enterpriseSecurityBadge"
                        :alt="data.navigation.enterpriseSecurityBadge.alt"
                        :expected-size="40"
                        :height="data.navigation.enterpriseSecurityBadge.height"
                        :source="data.navigation.enterpriseSecurityBadge.url"
                        :width="data.navigation.enterpriseSecurityBadge.width"
                      />
                    </UiComponentWrapper>
                  </AtomAnchor>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="more">
                <AtomButton
                  action="Navigation bar clicked"
                  :attributes="{
                    position: 'Enterprise',
                  }"
                  :label="data.componentOnDemandDemo.link"
                  :to="data.componentOnDemandDemo.link"
                  type="secondary"
                >
                  <UiComponentIcon type="on-demand" />
                  {{ data.componentGlobal.watchAnOnDemandDemo }}
                </AtomButton>
                <AtomButton
                  :action="requestDemoCallToAction.action"
                  :attributes="{
                    position: 'Enterprise',
                  }"
                  label="Navigation bar CTA"
                  :to="requestDemoCallToAction.to"
                  type="secondary"
                >
                  <UiComponentIcon type="phone" />
                  {{ data.componentGlobal.contactSales }}
                </AtomButton>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <!-- Menu > Items > Resources -->
        <UiComponentWrapper
          class="item"
          :class="{ active: activePaneIndex === 4 }"
          @click="screenWidth < 1328 ? setPaneIndex(4) : null"
          @mouseenter="screenWidth >= 1328 ? setPaneIndex(4) : null"
          @mouseleave="screenWidth >= 1328 ? resetPaneIndex() : null"
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.resourcesTitle }}
          </UiComponentParagraph>
          <UiComponentIcon type="chevron-down" />
          <UiComponentWrapper class="pane">
            <UiComponentWrapper class="inner">
              <UiComponentWrapper
                class="content resources"
                :class="{ splitted: data.navigation.resourcesCards.length }"
              >
                <UiComponentWrapper class="main">
                  <UiComponentParagraph class="title">
                    {{ data.navigation.resourcesSubtitle }}
                  </UiComponentParagraph>
                  <UiComponentWrapper class="list">
                    <AtomAnchor
                      v-for="
                        item in data.navigation.resourcesItems
                          .filter(({ displayedOnlyWhenLoggedIn }) => {
                            if (displayedOnlyWhenLoggedIn) {
                              return isLogged;
                            }

                            return true;
                          })
                      "
                      :key="`resource-${item.id}`"
                      class="list-item"
                      :class="{ 'has-flag': item.englishOnly }"
                      :force-reload="item.englishOnly"
                      :target="item.url.startsWith('http') ? '_blank' : undefined"
                      :to="item.url"
                      @click="
                        closeMobileMenu();
                        resetPaneIndex();
                        track('Navigation bar clicked', {
                          category: 'Button',
                          label: item.url,
                          locale,
                          position: 'Resources',
                        })
                      "
                    >
                      <UiComponentIcon :type="item.icon.type" />
                      <UiComponentParagraph>{{ item.title }}</UiComponentParagraph>
                    </AtomAnchor>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper
                  v-if="data.navigation.resourcesCards.length > 0"
                  class="aside"
                >
                  <AtomAnchor
                    v-for="card in data.navigation.resourcesCards"
                    :key="`resource-card-${card.id}`"
                    class="card"
                    :to="getRouteOfResourceCard(card)"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: getRouteOfResourceCard(card),
                        locale,
                        position: 'Resources',
                      })
                    "
                  >
                    <UiComponentWrapper class="cover">
                      <UiComponentImage
                        v-if="card.backgroundImage"
                        :key="`resource-card-cover-${resourceCoverSize}`"
                        :alt="card.backgroundImage.alt"
                        class="background"
                        :expected-size="resourceCoverSize"
                        :extra-params="{
                          fit: 'crop',
                          h: 169,
                          w: resourceCoverSize,
                        }"
                        :height="card.backgroundImage.height"
                        :source="card.backgroundImage.url"
                        use-natural-height
                        use-natural-width
                        :width="card.backgroundImage.width"
                      />
                      <MoleculeEbook
                        v-if="card.image"
                        :image="card.image"
                        :lazyload="props.lazyload"
                        orientation="portrait"
                        :size="121"
                      />
                    </UiComponentWrapper>
                    <UiComponentParagraph>{{ card.shortTitle }}</UiComponentParagraph>
                    <UiComponentButton type="text">
                      {{ data.componentGlobal.learnMore }}
                      <UiComponentIcon type="arrow-right" />
                    </UiComponentButton>
                  </AtomAnchor>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="more">
                <AtomButton
                  action="Navigation bar clicked"
                  :attributes="{
                    position: 'Resources',
                  }"
                  :label="data.componentOnDemandDemo.link"
                  :to="data.componentOnDemandDemo.link"
                  type="secondary"
                >
                  <UiComponentIcon type="on-demand" />
                  {{ data.componentGlobal.watchAnOnDemandDemo }}
                </AtomButton>
                <AtomButton
                  :action="requestDemoCallToAction.action"
                  :attributes="{
                    position: 'Resources',
                  }"
                  label="Navigation bar CTA"
                  :to="requestDemoCallToAction.to"
                  type="secondary"
                >
                  <UiComponentIcon type="phone" />
                  {{ data.componentGlobal.contactSales }}
                </AtomButton>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <!-- Menu > Items > Free Tools -->
        <UiComponentWrapper
          v-if="locale !== 'de'"
          class="item"
          :class="{ active: activePaneIndex === 5 }"
          @click="screenWidth < 1328 ? setPaneIndex(5) : null"
          @mouseenter="screenWidth >= 1328 ? setPaneIndex(5) : null"
          @mouseleave="screenWidth >= 1328 ? resetPaneIndex() : null"
        >
          <UiComponentParagraph class="title">
            {{ data.navigation.freeToolsTitle }}
          </UiComponentParagraph>
          <UiComponentIcon type="chevron-down" />
          <UiComponentWrapper class="pane">
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="content free-tools">
                <UiComponentWrapper class="main">
                  <UiComponentParagraph class="title">
                    {{ data.navigation.freeToolsSubtitle }}
                  </UiComponentParagraph>
                  <UiComponentWrapper class="list">
                    <AtomAnchor
                      v-for="item in data.navigation.freeToolsItems"
                      :key="`free-tools-item-${item.id}`"
                      class="list-item"
                      :class="{ 'has-flag': item.englishOnly }"
                      :force-reload="item.englishOnly"
                      :to="item.url"
                      @click="
                        closeMobileMenu();
                        resetPaneIndex();
                        track('Navigation bar clicked', {
                          category: 'Button',
                          label: item.url,
                          locale,
                          position: 'Free Tools',
                        })
                      "
                    >
                      <UiComponentIcon :type="item.icon.type" />
                      <UiComponentParagraph>{{ item.title }}</UiComponentParagraph>
                      <UiComponentParagraph>{{ item.text }}</UiComponentParagraph>
                    </AtomAnchor>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper class="aside">
                  <AtomAnchor
                    v-for="card in data.navigation.freeToolsCards"
                    :key="`free-tools-card-${card.id}`"
                    class="card"
                    :class="card.theme.toLowerCase()"
                    :style="{ backgroundColor: card.backgroundColor.hex }"
                    :to="localePath('webinar-tools')"
                    @click="
                      closeMobileMenu();
                      resetPaneIndex();
                      track('Navigation bar clicked', {
                        category: 'Button',
                        label: localePath('webinar-tools'),
                        locale,
                        position: 'Free Tools',
                      })
                    "
                  >
                    <UiComponentWrapper class="illustration">
                      <UiComponentWrapper class="container">
                        <UiComponentImage
                          v-if="card.illustration"
                          :key="`webinar-tools-illustation-${toolboxIllustrationSize}`"
                          :alt="card.illustration.alt"
                          :expected-size="toolboxIllustrationSize"
                          :height="card.illustration.height"
                          :source="card.illustration.url"
                          :width="card.illustration.width"
                        />
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <UiComponentWrapper class="metadata">
                      <UiComponentParagraph>{{ card.title }}</UiComponentParagraph>
                      <UiComponentParagraph>{{ card.text }}</UiComponentParagraph>
                    </UiComponentWrapper>
                  </AtomAnchor>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="more">
                <AtomButton
                  action="Navigation bar clicked"
                  :attributes="{
                    position: 'Free Tools',
                  }"
                  :label="data.componentOnDemandDemo.link"
                  :to="data.componentOnDemandDemo.link"
                  type="secondary"
                >
                  <UiComponentIcon type="on-demand" />
                  {{ data.componentGlobal.watchAnOnDemandDemo }}
                </AtomButton>
                <AtomButton
                  :action="requestDemoCallToAction.action"
                  :attributes="{
                    position: 'Free Tools',
                  }"
                  label="Navigation bar CTA"
                  :to="requestDemoCallToAction.to"
                  type="secondary"
                >
                  <UiComponentIcon type="phone" />
                  {{ data.componentGlobal.contactSales }}
                </AtomButton>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>

      <!-- Menu > Actions -->
      <UiComponentWrapper class="actions">
        <AtomButton
          v-if="!isLogged"
          :action="loginCallToAction.action"
          class="log-in"
          label="Navigation bar CTA"
          :to="loginCallToAction.to"
          type="text"
        >
          {{ loginCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="requestDemoCallToAction.action"
          class="demo"
          label="Navigation bar CTA"
          :to="requestDemoCallToAction.to"
          type="secondary"
        >
          {{ requestDemoCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="signupOrMyDashboardCallToAction.action"
          class="sign-up"
          label="Navigation bar CTA"
          :theme="props.theme"
          :to="signupOrMyDashboardCallToAction.to"
        >
          {{ signupOrMyDashboardCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
    </UiComponentWrapper>

    <!-- Burger -->
    <UiComponentWrapper
      class="burger"
      @click="toggleMobileMenu"
    >
      <UiComponentIcon
        :theme="menuIsOpened ? 'light' : props.theme"
        :type="menuIcon"
      />
    </UiComponentWrapper>
  </nav>
</template>

<script setup>
// Composables
const { track } = useAnalyticsJS()
const { state } = useConditionalPreviewMode()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const localePath = useLocalePath()
const { $slugify } = useNuxtApp()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const { height: screenHeight, width: screenWidth } = useScreenSize()

// Props
const props = defineProps({
  shouldRefresh: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Async Data
const { data } = await useFetch(`/api/content/components/navigation/${locale.value}`, {
  key: `component-navigation-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// Refs
const activePaneIndex = ref(-1)
const menuHeight = ref(0)
const menuIsOpened = ref(false)

// Computed Properties
const loginCallToAction = computed(() => {
  return {
    action: 'Log in clicked',
    slot: data.value.componentGlobal.logIn,
    to: `https://${publicRuntimeConfig.appBaseUrl}/#/login`,
  }
})

const menuIcon = computed(() => {
  return menuIsOpened.value ? 'x' : 'menu'
})

const requestDemoCallToAction = computed(() => {
  return {
    action: 'Demo clicked',
    slot: data.value.componentGlobal.ctaGetADemoShort,
    to: '#form-demo',
  }
})

const resourceCoverSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return screenWidth.value - 32
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return (((screenWidth.value - 48) - 16) / 2)
    default:
      return 302
  }
})

const signupOrMyDashboardCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'My dashboard clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/`,
        slot: data.value.componentGlobal.myDashboard,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.componentGlobal.ctaGetStartedForFree,
      }
})

const toolboxIllustrationSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return 352
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return 243
    default:
      return 302
  }
})

// Watchers
watch(
  () => menuIsOpened.value,
  () => {
    if (!menuIsOpened.value) {
      activePaneIndex.value = -1
    }
    else {
      menuHeight.value = getMenuHeight()
    }
    toggleScrollOnBody()
  },
)

watch(
  () => props.shouldRefresh,
  () => {
    nextTick(() => {
      menuHeight.value = getMenuHeight()
    })
  },
)

watch(
  () => screenWidth.value,
  () => {
    if (menuIsOpened.value && screenWidth.value >= 1328) {
      menuIsOpened.value = false
    }
  },
)

// Methods
function closeMobileMenu() {
  menuIsOpened.value = false
}

function getMenuHeight() {
  const banner = document?.querySelector('.app__banner')
  const offsetTop = screenWidth.value < 768 ? 56 : 72
  return screenHeight.value - offsetTop - (banner?.offsetHeight || 0)
}

function getRouteOfProductItem(item) {
  switch (item.slug) {
    case 'online-meeting-software':
      return localePath({
        name: 'online-meeting-software',
      })
    case 'virtual-event-software':
      return localePath({
        name: 'virtual-event-software',
      })
    case 'webinar-software':
      return localePath({
        name: 'webinar-software',
      })
    case 'automated-webinar':
      return localePath({
        name: 'webinar-software-automated-webinar',
      })
    case 'on-demand-webinar':
      return localePath({
        name: 'webinar-software-on-demand',
      })
    case 'restreaming':
      return localePath({
        name: 'restreaming',
      })
    case 'embed':
      return localePath({
        name: 'embed',
      })
  }
}

function getRouteOfResourceCard(item) {
  switch (item.__typename) {
    case 'BlogPostRecord':
      return localePath({
        name: 'blog-slug',
        params: {
          slug: item.slug,
        },
      })
    case 'ModelOfferRecord':
      return localePath({
        name: 'resources-type-slug',
        params: {
          slug: item.slug,
          type: $slugify(item.resourceType.name),
        },
      })
    case 'PillarPageRecord':
      return localePath({
        name: 'resources-guides-slug',
        params: {
          slug: item.slug,
        },
      })
    case 'QuizRecord':
      return localePath({
        name: 'resources-quiz-slug',
        params: {
          slug: item.slug,
        },
      })
  }
}

function resetPaneIndex() {
  activePaneIndex.value = -1
}

function setPaneIndex(value) {
  if (activePaneIndex.value === value) {
    activePaneIndex.value = -1
  }
  else {
    activePaneIndex.value = value
  }
}

function toggleMobileMenu() {
  menuIsOpened.value = !menuIsOpened.value
}

function toggleScrollOnBody() {
  if (menuIsOpened.value) {
    document.body.classList.add('no-scroll')
  }
  else {
    document.body.classList.remove('no-scroll')
  }
}
</script>

<style lang="postcss" scoped>
.navigation {
  @apply relative flex items-center justify-between w-full h-14 bg-transparent;

  @screen md {
    @apply h-18;
  }

  &.dark {
    .menu {
      .items {
        .item {
          &.active {
            @screen xl {
              .title {
                @apply text-white;
              }

              .icon-chevron-down {
                @apply text-white;
              }
            }
          }

          > .title {
            @screen xl {
              @apply text-grey-blue-300;
            }
          }

          .icon-chevron-down {
            @screen xl {
              @apply text-grey-blue-300;
            }
          }

          .pane {
            .inner {
              .content {
                &.product {
                  .main {
                    .list {
                      .list-item {
                        .icon {
                          @apply text-winter-green-600;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .actions {
        .button {
          &.primary {
            @apply border-winter-green-700;
          }
        }
      }
    }
  }

  &.light {
    .menu {
      .items {
        .item {
          &.active {
            @screen xl {
              &::before {
                @apply opacity-100;
              }

              .title {
                @apply text-livestorm-blue-700;
              }

              .icon-chevron-down {
                @apply text-livestorm-blue-700;
              }
            }
          }

          .pane {
            .inner {
              .content {
                &.product {
                  .main {
                    .list {
                      .list-item {
                        .icon {
                          @apply text-livestorm-blue-500;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .actions {
        .button {
          &.primary {
            @apply border-[#0B41C1];
          }
        }
      }
    }
  }

  &.opened {
    @apply bg-white;

    &::before,
    &::after {
      @apply bg-white;
    }

    .menu {
      @apply opacity-100 visible min-h-[-webkit-fill-available] h-[var(--height)];
    }
  }

  &::before,
  &::after {
    @apply absolute h-full w-4 bg-transparent content-[''];

    @screen md {
      @apply w-6;
    }

    @screen lg {
      width: calc((100vw - 100%) / 2);
    }

    @screen xl {
      width: calc((100vw - 1280px) / 2);
    }
  }

  &::before {
    @apply -left-4;

    @screen md {
      @apply -left-6;
    }

    @screen lg {
      left: calc((100vw - 100%) / -2);
    }
  }

  &::after {
    @apply -right-4;

    @screen md {
      @apply -right-6;
    }

    @screen lg {
      right: calc((100vw - 100%) / -2);
    }
  }

  .logo {
    @apply flex-shrink-0 w-auto transform -translate-y-0.5;

    :deep(img) {
      @apply h-6 w-auto;
    }
  }

  .menu {
    @apply absolute -left-4 -right-4 top-14 flex-col gap-4 h-0 pb-4 opacity-0 invisible w-screen bg-white overflow-scroll;

    @screen md {
      @apply -left-6 -right-6 top-[72px] pb-6;
    }

    @screen xl {
      @apply static flex-row items-center justify-between gap-0 h-full w-full ml-4 p-0 bg-transparent opacity-100 visible overflow-auto;
    }

    .items {
      @apply flex-col;

      @screen xl {
        @apply flex-row h-full w-auto;
      }

      .item {
        @apply relative flex flex-col w-screen;

        @screen xl {
          @apply flex-row items-center gap-1 h-full w-auto px-3;
        }

        &::before {
          @apply absolute bottom-4 left-0 right-0 top-4 flex bg-grey-blue-100 rounded-lg opacity-0 z-0 transition-opacity duration-300 ease-in-out;

          @screen xl {
            @apply content-[''];
          }
        }

        &.active {
          > .title {
            @apply font-medium text-winter-green-900 border-transparent;
          }

          .icon-chevron-down {
            @apply rotate-180;
          }

          .pane {
            @apply max-h-[1400px] opacity-100 visible;
          }

          @screen xl {
            .title {
              @apply font-normal;
            }

            .icon-chevron-down {
              @apply translate-y-0.5 rotate-0;
            }
          }
        }

        > .title {
          @apply text-grey-blue-600 mx-4 py-4 border-b border-grey-blue-100 transition-all duration-300 ease-in-out;

          @screen md {
            @apply mx-6;
          }

          @screen xl {
            @apply relative mx-0 py-0 border-none text-base leading-[normal] z-10 transition-colors;
          }
        }

        .icon-chevron-down {
          @apply absolute right-4 top-[22px] text-winter-green-900 transform rotate-0 transition-transform duration-300 ease-in-out;

          @screen md {
            @apply right-6;
          }

          @screen xl {
            @apply static text-grey-blue-600 text-xs leading-none z-10 transform-none transition-all;
          }
        }

        .pane {
          @apply justify-center max-h-0 opacity-0 invisible transition-all duration-300 ease-in-out;

          @screen xl {
            @apply fixed left-0 right-0 top-20 max-h-none;
          }

          .inner {
            @apply flex flex-col max-w-none w-[1312px] overflow-hidden;

            @screen xl {
              @apply rounded-2xl shadow-light2;
            }

            .content {
              @apply pt-2 pb-4 bg-white;

              @screen xl {
                @apply p-4;
              }

              &.product {
                @apply flex-col gap-4;

                @screen xl {
                  @apply flex-row items-start gap-10;
                }

                .main {
                  @apply flex-col gap-2;

                  @screen xl {
                    @apply mt-4;
                  }

                  .title {
                    @apply px-4 font-medium text-winter-green-900 text-base leading-[normal];

                    @screen md {
                      @apply px-6;
                    }

                    @screen xl {
                      @apply ml-4 px-0;
                    }
                  }

                  .list {
                    @apply flex-col mt-2 px-4;

                    @screen md {
                      @apply flex-row flex-wrap gap-x-4 px-6;
                    }

                    @screen xl {
                      @apply mt-0 px-0;
                    }

                    .list-item {
                      @apply flex gap-3 h-auto py-4 bg-white rounded-lg transition-colors duration-300 ease-in-out;

                      @screen md {
                        width: calc((100% - 32px) / 3);
                      }

                      @screen xl {
                        @apply relative flex-col gap-0 px-11 pr-4;
                        width: calc((100% - 16px) / 2);

                        &:hover {
                          @apply bg-grey-blue-100;
                        }
                      }

                      .icon {
                        @apply p-[1.667px] text-[16.7px] leading-none;

                        @screen xl {
                          @apply absolute left-4 top-4;
                        }
                      }

                      .title {
                        @apply px-0 font-medium text-winter-green-900 text-[13px] leading-5;

                        @screen xl {
                          @apply ml-0;
                        }
                      }

                      .description {
                        @apply hidden;

                        @screen xl {
                          @apply flex text-[13px] leading-5;
                        }
                      }
                    }
                  }
                }

                .aside {
                  @apply flex-col gap-4 px-4;

                  @screen md {
                    @apply flex-row px-6;
                  }

                  @screen xl {
                    @apply px-0;
                  }

                  .features {
                    @apply flex-col p-6 bg-blizzard-100 border border-blizzard-100 rounded-lg transition-colors duration-300 ease-in-out;

                    @screen md {
                      width: calc((100% - 16px) / 2);
                    }

                    @screen xl {
                      @apply relative overflow-hidden;

                      &:hover {
                        @apply border border-blizzard-200;
                      }
                    }

                    .title {
                      @apply font-medium text-winter-green-900 text-base leading-[normal];
                    }

                    .description {
                      @apply mt-1 text-[13px] leading-5;
                    }

                    .illustration {
                      @apply hidden;

                      @screen xl {
                        @apply absolute bottom-0 -right-[146px] flex rounded-none;
                      }
                    }
                  }

                  .integrations {
                    @apply relative flex-col p-6 bg-sirocco-100 border border-sirocco-100 rounded-lg transition-colors duration-300 ease-in-out;

                    @screen md {
                      width: calc((100% - 16px) / 2);
                    }

                    @screen xl {
                      @apply relative overflow-hidden;

                      &:hover {
                        @apply border-sirocco-200;

                        .button {
                          @apply bg-sirocco-300 text-winter-green-900;

                          .icon {
                            @apply translate-x-2;
                          }
                        }
                      }
                    }

                    &::after {
                      @screen xl {
                        @apply absolute bottom-0 left-0 right-0 h-26 w-full bg-gradient-to-b from-transparent from-0% to-[#FDF6F2] to-20% content-[''] z-10;
                      }
                    }

                    .title {
                      @apply font-medium text-winter-green-900 text-base leading-[normal];
                    }

                    .description {
                      @apply mt-1 text-[13px] leading-5;
                    }

                    .highlighted {
                      @apply hidden;

                      @screen xl {
                        @apply flex flex-col gap-3 mt-4 z-0;
                      }

                      .integration {
                        @apply items-center gap-2;

                        .logo {
                          @apply rounded-none;
                        }

                        p {
                          @apply text-winter-green-900 text-sm leading-5;
                        }
                      }
                    }

                    .button {
                      @apply hidden;

                      @screen xl {
                        @apply relative flex w-full mt-6 bg-sirocco-200 text-winter-green-900 text-sm leading-6 rounded-lg z-20;

                        &:hover {
                          @apply bg-sirocco-300 text-winter-green-900;
                        }
                      }

                      .icon {
                        @apply text-winter-green-900;
                      }
                    }
                  }
                }
              }

              &.solutions {
                @apply flex-col gap-8;

                @screen xl {
                  @apply flex-row gap-10 pt-8;
                }

                .main {
                  @apply flex-col;

                  .title {
                    @apply px-4 font-medium text-winter-green-900 text-base leading-[normal];

                    @screen md {
                      @apply px-6;
                    }

                    @screen xl {
                      @apply pr-0 pl-4;
                    }
                  }

                  .list {
                    @apply px-4;

                    @screen md {
                      @apply px-6;
                    }

                    @screen xl {
                      @apply px-0;
                    }

                    &.highlight {
                      @apply flex-col gap-4 mt-2;

                      @screen md {
                        @apply flex-row;
                      }

                      .list-item {
                        @apply relative flex flex-col p-4 border border-transparent rounded-lg overflow-hidden transition-colors duration-300 ease-in-out;

                        @screen md {
                          width: calc((100% - 16px) / 2);
                        }

                        @screen xl {
                          @apply h-[177px];
                        }

                        &.spring-green {
                          @apply bg-spring-green-100;

                          @screen xl {
                            &:hover {
                              @apply border-spring-green-300;
                            }
                          }

                          .icon {
                            @apply text-spring-green-600;
                          }
                        }

                        &.winter-green {
                          @apply bg-winter-green-100;

                          @screen xl {
                            &:hover {
                              @apply border-winter-green-200;
                            }
                          }

                          .icon {
                            @apply text-winter-green-600;
                          }
                        }

                        .icon {
                          @apply absolute left-4 top-4 p-[1.667px] text-[16.7px] leading-none;
                        }

                        p {
                          @apply ml-[28px] font-medium text-winter-green-900 text-[13px] leading-5;

                          & + p {
                            @apply font-normal text-grey-blue-600;
                          }
                        }

                        .illustration {
                          @apply hidden;

                          @screen xl {
                            @apply flex mt-4 rounded-[3px];
                          }
                        }
                      }
                    }

                    &.classic {
                      @apply flex-col mt-4;

                      @screen md {
                        @apply flex-row;
                      }

                      @screen md {
                        @apply gap-4;
                      }

                      .list-item {
                        @apply flex items-center gap-3 py-4;

                        @screen md {
                          width: calc((100% - 32px) / 3);
                        }

                        @screen xl {
                          @apply px-4 bg-white rounded-lg transition-colors duration-300 ease-in-out;

                          &:hover {
                            @apply bg-grey-blue-100;
                          }
                        }

                        .icon {
                          @apply p-[1.667px] text-grey-blue-500 text-[16.7px] leading-none;
                        }

                        p {
                          @apply font-medium text-winter-green-900 text-[13px] leading-5;
                        }
                      }
                    }
                  }

                  .button {
                    @apply w-full mx-4 mt-4 bg-grey-blue-100 border border-grey-blue-200 text-sm leading-6 rounded-lg;
                    max-width: calc(100vw - 32px);

                    @screen md {
                      @apply mx-6;
                      max-width: calc(100vw - 48px);
                    }

                    @screen xl {
                      @apply max-w-none mx-0 mt-auto;
                    }

                    &:hover {
                      @apply bg-grey-blue-200;
                    }
                  }
                }

                .aside {
                  @apply flex-col gap-8 px-4;

                  @screen md {
                    @apply flex-row items-stretch gap-4 px-6;
                  }

                  @screen xl {
                    @apply gap-10 px-0;
                  }

                  .teams,
                  .use-cases {
                    @apply flex-col;

                    @screen md {
                      width: calc((100% - 16px) / 2);
                    }

                    @screen xl {
                      width: calc((100% - 40px) / 2);
                    }

                    .title {
                      @apply font-medium text-winter-green-900 text-base leading-[normal];
                    }

                    .list {
                      @apply relative flex-col h-full mt-2;

                      .list-item {
                        @apply flex items-center gap-3 w-full py-3;

                        @screen xl {
                          @apply px-4 bg-white rounded-lg transition-colors duration-300 ease-in-out;
                        }

                        &:hover {
                          @apply bg-grey-blue-100;
                        }

                        .icon {
                          @apply p-[1.667px] text-grey-blue-500 text-[16.7px] leading-none;
                        }

                        p {
                          @apply font-medium text-winter-green-900 text-[13px] leading-5;
                        }
                      }

                      &::after {
                        @apply absolute bottom-0 left-0 right-0 flex h-[45px] w-full bg-gradient-to-b from-transparent to-white content-[''];
                      }
                    }

                    .button {
                      @apply flex-shrink-0 w-full bg-grey-blue-100 border border-grey-blue-200 text-sm leading-6 rounded-lg;

                      &:hover {
                        @apply bg-grey-blue-200;
                      }
                    }
                  }

                  .teams {
                    .list {
                      &::after {
                        @apply content-none;

                        @screen md {
                          @apply content-[''];
                        }
                      }
                    }
                  }
                }
              }

              &.enterprise {
                @apply flex-col gap-4 pt-2;

                @screen xl {
                  @apply flex-row gap-10 p-4;
                }

                .main {
                  @apply px-4;

                  @screen md {
                    @apply px-6;
                  }

                  @screen xl {
                    @apply w-[620px] px-0;
                  }

                  .card {
                    @apply relative w-full pl-[32px];

                    @screen md {
                      @apply h-[173px];
                    }

                    @screen xl {
                      @apply h-auto p-6 pl-14;
                    }

                    &:hover {
                      .button {
                        :deep(.icon) {
                          @apply translate-x-2;
                        }
                      }
                    }

                    > .icon {
                      @apply absolute left-0 p-0.5 text-grey-blue-500 text-xl leading-none;

                      @screen xl {
                        @apply left-6 top-6;
                      }
                    }

                    p {
                      @apply relative max-w-[264px] font-medium text-winter-green-900 text-base leading-6 z-10;

                      & + p {
                        @apply mt-1 font-normal text-grey-blue-600 text-[13px] leading-5;
                      }
                    }

                    .button {
                      @apply h-6 mt-3 px-0 font-medium text-winter-green-900 text-sm leading-6;
                    }

                    .illustration {
                      @apply hidden;

                      @screen md {
                        @apply absolute right-6 -top-1.5 flex w-auto;
                      }

                      @screen xl {
                        @apply -right-[30px] top-0;
                      }

                      picture {
                        @apply rounded-none;
                      }
                    }
                  }
                }

                .aside {
                  @apply flex-col gap-4 px-4;

                  @screen md {
                    @apply px-6;
                  }

                  @screen xl {
                    @apply w-[620px] px-0;
                  }

                  .card {
                    @apply relative flex flex-col p-4 pl-12 border rounded-lg;

                    @screen md {
                      @apply h-[92px];
                    }

                    @screen xl {
                      @apply justify-center h-auto transition-colors duration-500 ease-in-out;
                    }

                    &.security {
                      @apply bg-blizzard-100 border-transparent;

                      &:hover {
                        @screen xl {
                          @apply border-blizzard-300;
                        }
                      }

                      > .icon {
                        @apply text-blizzard-600;
                      }

                      p + p {
                        @apply max-w-[240px];

                        @screen md {
                          @apply max-w-[500px];
                        }
                      }
                    }

                    &.vip {
                      @apply bg-winter-green-100 border-transparent;

                      &:hover {
                        @screen xl {
                          @apply border-winter-green-300;
                        }
                      }

                      > .icon {
                        @apply text-winter-green-500;
                      }
                    }

                    > .icon {
                      @apply absolute left-4 top-4 p-[1.667px] text-[16.7px] leading-none;
                    }

                    p {
                      @apply items-center gap-1 font-medium text-winter-green-900 text-[13px] leading-5;

                      .icon {
                        @apply text-winter-green-900 text-sm leading-none;
                      }

                      & + p {
                        @apply max-w-[504px] font-normal text-grey-blue-600;
                      }
                    }

                    .badge {
                      @apply absolute right-4 self-center w-auto;

                      picture {
                        @apply rounded-none;
                      }
                    }
                  }
                }
              }

              &.resources {
                @apply flex-col gap-4 pt-2;

                @screen xl {
                  @apply flex-row gap-10 p-4;
                }

                &.splitted {
                  .main {
                    @screen xl {
                      @apply w-[620px];
                    }

                    .list {
                      .list-item {
                        @screen xl {
                          width: calc((100% - 20px) / 2);
                        }
                      }
                    }
                  }
                }

                .main {
                  @apply flex-col gap-2;

                  @screen xl {
                    @apply w-full mt-4;
                  }

                  .title {
                    @apply px-4 font-medium text-winter-green-900 text-base leading-[normal];

                    @screen md {
                      @apply px-6;
                    }

                    @screen xl {
                      @apply ml-4 px-0;
                    }
                  }

                  .list {
                    @apply flex-col mt-2 px-4;

                    @screen md {
                      @apply flex-row flex-wrap gap-x-4 px-6;
                    }

                    @screen xl {
                      @apply gap-x-5 px-0;
                    }

                    .list-item {
                      @apply flex items-center gap-3 py-4;

                      @screen md {
                        width: calc((100% - 32px) / 3);
                      }

                      @screen xl {
                        @apply bg-white px-4 rounded-lg transition-colors duration-500 ease-in-out;
                        width: calc((100% - 60px) / 4);
                      }

                      &.has-flag {
                        @apply relative;

                        &::after {
                          @apply absolute left-1.5 top-8 px-1 py-0.5 bg-grey-blue-200 font-bold text-grey-blue-600 text-[8px] leading-[8px] uppercase rounded content-['en'] z-0;

                          @screen md {
                            @apply left-6;
                          }
                        }
                      }

                      &:hover {
                        @screen xl {
                          @apply bg-grey-blue-100;
                        }
                      }

                      .icon {
                        @apply p-[1.667px] text-grey-blue-500 text-[16.7px] leading-none z-10;
                      }

                      p {
                        @apply font-medium text-winter-green-900 text-[13px] leading-5;
                      }
                    }
                  }
                }

                .aside {
                  @apply flex-col gap-4 px-4;

                  @screen md {
                    @apply flex-row px-6;
                  }

                  @screen xl {
                    @apply w-[620px] px-0;
                  }

                  .card {
                    @apply flex flex-col;

                    @screen md {
                      width: calc((100% - 16px) / 2);
                    }

                    &:hover {
                      .button {
                        :deep(.icon) {
                          @apply translate-x-2;
                        }
                      }
                    }

                    .cover {
                      @apply relative items-center justify-center h-[169px] w-full rounded-lg overflow-hidden;

                      .background {
                        @apply absolute inset-0 rounded-none;
                      }

                      .ebook {
                        @apply h-[137px] w-auto !important;
                      }
                    }

                    p {
                      @apply mt-4 font-medium text-winter-green-900 text-base leading-[normal];
                    }

                    .button {
                      @apply self-start h-6 mt-3 px-0 text-sm leading-6;
                    }
                  }
                }
              }

              &.free-tools {
                @apply flex-col gap-4 pt-2;

                @screen xl {
                  @apply flex-row gap-10 p-4;
                }

                .main {
                  @apply flex-col gap-2;

                  @screen xl {
                    @apply mt-4;
                  }

                  .title {
                    @apply px-4 font-medium text-winter-green-900 text-base leading-[normal];

                    @screen md {
                      @apply px-6;
                    }

                    @screen xl {
                      @apply ml-4 px-0;
                    }
                  }

                  .list {
                    @apply flex-col mt-2 px-4;

                    @screen md {
                      @apply flex-row flex-wrap gap-x-4 px-6;
                    }

                    @screen xl {
                      @apply gap-x-5 px-0;
                    }

                    .list-item {
                      @apply relative flex flex-col py-4 pl-8;

                      @screen md {
                        width: calc((100% - 32px) / 3);
                      }

                      @screen xl {
                        @apply p-4 pl-12 bg-white rounded-lg transition-colors duration-500 ease-in-out;
                        width: calc((100% - 40px) / 3);
                      }

                      &.has-flag {
                        @apply relative;

                        &::after {
                          @apply absolute left-1.5 top-8 px-1 py-0.5 bg-grey-blue-200 font-bold text-grey-blue-600 text-[8px] leading-[8px] uppercase rounded content-['en'] z-0;

                          @screen md {
                            @apply left-6;
                          }
                        }
                      }

                      &:hover {
                        @screen xl {
                          @apply bg-grey-blue-100;
                        }
                      }

                      .icon {
                        @apply absolute left-0 p-[1.667px] text-grey-blue-500 text-[16.7px] leading-none;

                        @screen xl {
                          @apply left-4;
                        }
                      }

                      p {
                        @apply font-medium text-winter-green-900 text-[13px] leading-5;

                        & + p {
                          @apply hidden;

                          @screen xl {
                            @apply flex font-normal text-grey-blue-600;
                          }
                        }
                      }
                    }
                  }
                }

                .aside {
                  @apply px-4;

                  @screen md {
                    @apply px-6;
                  }

                  @screen xl {
                    @apply max-w-[302px] px-0;
                  }

                  .card {
                    @apply flex flex-col h-[342px] w-full p-6 rounded-lg overflow-hidden;

                    @screen md {
                      @apply flex-row items-center h-[180px] p-0;
                    }

                    @screen xl {
                      @apply flex-col items-start h-[342px] p-6 transition-all duration-500 ease-in-out;
                    }

                    &:hover {
                      @screen xl {
                        @apply brightness-110;
                      }
                    }

                    &.dark {
                      p {
                        @apply text-white;
                      }
                    }

                    &.light {
                      p {
                        @apply text-winter-green-900;

                        & + p {
                          @apply text-grey-blue-600;
                        }
                      }
                    }

                    .illustration {
                      @apply relative flex-shrink-0 h-[213px] w-full z-0;

                      @screen md {
                        @apply justify-start w-[243px];
                      }

                      @screen xl {
                        @apply w-full;
                      }

                      .container {
                        @apply absolute left-0 -top-[76px] justify-center;

                        @screen md {
                          @apply left-0 -top-[31px] w-[243px];
                        }

                        @screen xl {
                          @apply left-0 -top-[51px] w-full;
                        }

                        picture {
                          @apply rounded-none;
                        }
                      }
                    }

                    .metadata {
                      @apply flex-col mt-auto;

                      @screen md {
                        @apply mt-0;
                      }

                      @screen xl {
                        @apply mt-auto;
                      }

                      p {
                        @apply relative font-medium leading-[normal] z-10;

                        & + p {
                          @apply mt-1 font-normal text-[13px] leading-5;
                        }
                      }
                    }
                  }
                }
              }
            }

            .more {
              @apply flex-col gap-2 p-4 bg-grey-blue-100 border-t border-grey-blue-200;

              @screen md {
                @apply flex-row justify-end;
              }

              @screen xl {
                @apply justify-start;
              }

              .button {
                @apply gap-2 bg-grey-blue-200 border border-grey-blue-300 text-sm leading-6 rounded-[10px];

                &:hover {
                  @apply bg-grey-blue-300;
                }

                .icon {
                  @apply text-base leading-none;
                }
              }
            }
          }
        }
      }
    }

    .actions {
      @apply flex-col gap-4 w-auto mt-auto px-4;

      @screen md {
        @apply px-6;
      }

      @screen xl {
        @apply flex-row gap-3 mt-0 px-0;
      }

      .button {
        @apply h-12 text-origin leading-12;

        @screen xl {
          @apply h-10 text-base leading-[normal];
        }

        &.primary {
          @apply border;
        }

        &.secondary {
          @apply bg-gradient-to-b from-[#0C4AD] from-[90%] to-[#0B42C3100%] to-[100%] border border-grey-blue-200;
        }

        &.text {
          @apply h-10 font-normal text-base text-grey-blue-600 leading-10;

          &:hover {
            @apply text-livestorm-blue-700;
          }
        }
      }
    }
  }

  .burger {
    @apply h-5 w-5 cursor-pointer;

    @screen xl {
      @apply hidden;
    }

    .icon {
      @apply text-xl leading-none;
    }
  }
}
</style>
