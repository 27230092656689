import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "id": "strictly-necessary",
        "name": {
          "en": "Strictly Necessary",
          "es": "Estrictamente necesarias",
          "fr": "Strictement nécessaires",
          "de": "Streng notwendige"
        },
        "description": {
          "en": "Needed to make the website work.",
          "es": "Necesarias para que el sitio web funcione.",
          "fr": "Nécessaires pour faire fonctionner le site web.",
          "de": "Musste die Website zum Laufen bringen."
        },
        "links": {
          "https://policies.google.com/": "Google Tag Manager",
          "https://livestorm.co/privacy-policy": "Livestorm",
          "https://partnerstack.com/policies#privacy-policy": "PartnerStack",
          "https://www.twilio.com/en-us/legal/privacy": "Segment"
        }
      },
      {
        "id": "analytics-internal-use",
        "name": {
          "en": "Analytics (Internal use)",
          "es": "Analíticas (uso interno)",
          "fr": "Analytiques (usage interne)",
          "de": "Analytik (Internes Vorgehen)"
        },
        "description": {
          "en": "Basic analytics for internal traffic reporting only.",
          "es": "Analítica básica sólo para informes de tráfico interno.",
          "fr": "Analytiques basiques et anonymes pour mesure interne du trafic.",
          "de": "Grundlagenanalysen nur für die interne Verkehrsmeldung."
        },
        "links": {
          "https://amplitude.com/privacy": "Amplitude",
          "https://www.attributionapp.com/privacy/": "Attribution",
          "https://policies.google.com/privacy": "Google Analytics",
          "https://matomo.org/privacy-policy/": "Matomo"
        }
      },
      {
        "id": "live-chat",
        "name": {
          "en": "Live chat",
          "es": "Chat en vivo",
          "fr": "Chat en direct",
          "de": "Live-Chat"
        },
        "description": {
          "en": "Used to answer questions to website's visitors and guide them in their discovery of Livestorm.",
          "es": "Usadas para contestar las preguntas de los visitantes del sitio web y guiarlos en su descubrimiento de Livestorm.",
          "fr": "Utilisés pour répondre aux questions des visiteurs du site et les guider dans leur découverte de Livestorm.",
          "de": "Hat Fragen von Besuchern der Website beantwortet und sie bei ihrer Entdeckung von Livestorm angeleitet."
        },
        "links": {
          "https://www.intercom.com/legal/privacy": "Intercom"
        }
      }
    ],
    "optional": [
      {
        "id": "advertising",
        "name": {
          "en": "Advertising",
          "es": "Publicitarias",
          "fr": "Publicitaires",
          "de": "Werbung"
        },
        "description": {
          "en": "Used to display ads to website visitors according to their interactions with Livestorm.",
          "es": "Usadas para enseñar anuncios a los visitantes del sitio web según sus interacciones con Livestorm.",
          "fr": "Utilisés pour afficher des publicités aux visiteurs du site web en fonction de leurs interactions avec Livestorm.",
          "de": "Wird verwendet, um Webseitenbesuchern Anzeigen basierend auf ihren Interaktionen mit Livestorm anzuzeigen."
        },
        "links": {
          "https://www.capterra.com/legal/privacy-policy/": "Capterra",
          "https://www.facebook.com/full_data_use_policy": "Facebook",
          "https://policies.google.com/privacy": "Google Ads",
          "https://www.linkedin.com/legal/privacy-policy": "LinkedIn",
          "https://privacy.microsoft.com/en-us/privacystatement/": "Microsoft Services"
        }
      },
      {
        "id": "analytics",
        "name": {
          "en": "Analytics",
          "es": "Analíticas",
          "fr": "Analytiques",
          "de": "Analytik"
        },
        "description": {
          "en": "Used for traffic analysis",
          "es": "Usadas para el análisis del tráfico.",
          "fr": "Utilisés pour l'analyse du trafic.",
          "de": "Verwendet für die Verkehrs’analyse"
        },
        "links": {
          "https://clearbit.com/privacy-policy": "Clearbit",
          "https://customer.io/legal/privacy-policy/": "Customer.io",
          "https://legal.hubspot.com/privacy-policy": "Hubspot",
          "https://www.salesloft.com/privacy-notice/": "SalesLoft",
          "https://www.smartlook.com/privacy": "Smartlook"
        }
      },
      {
        "id": "website-personalization",
        "name": {
          "en": "Website Personalization",
          "es": "Personalización del sitio web",
          "fr": "Personnalisation du site web",
          "de": "Website-Personalisierung"
        },
        "description": {
          "en": "Using anonymized data to personalize the website.",
          "es": "Uso de datos anónimos para personalizar el sitio web.",
          "fr": "Utilisation de données anonymes pour personnaliser le site web.",
          "de": "Anonymisierte Daten verwenden, um die Website zu personalisieren."
        },
        "links": {
          "https://clearbit.com/privacy-policy": "Clearbit",
          "https://www.mutinyhq.com/privacy": "Mutiny"
        }
      },
      {
        "id": "receive-marketing-emails",
        "name": {
          "en": "Receive marketing emails",
          "es": "Recibir emails de marketing",
          "fr": "Recevoir des courriels de marketing",
          "de": "Marketing-E-Mails erhalten"
        },
        "description": {
          "en": "Used to send relevant emails such as upcoming events from other Livestorm customers, or communications about Livestorm. This can be opted out at any time, directly from the emails.",
          "es": "Utilizados para enviar correos electrónicos relevantes como próximos eventos de otros clientes de Livestorm, o comunicaciones sobre Livestorm. Puedes darte de baja en cualquier momento, directamente desde los correos electrónicos.",
          "fr": " Utilisés pour envoyer des courriels pertinents tels que les événements à venir d'autres clients de Livestorm, ou des communications sur Livestorm. Cette option peut être désactivée à tout moment, directement dans les courriels.",
          "de": "Verwendet, um relevante E-Mails zu senden, wie z. B. bevorstehende Veranstaltungen von anderen Livestorm-Kunden oder Mitteilungen über Livestorm. Dies kann jederzeit direkt aus den E-Mails abbestellt werden."
        },
        "links": {}
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": true,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "es",
    "fr",
    "de",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Agree and close",
      "acceptAll": "Accept all",
      "bannerDescription": "<span data-token-index=\"0\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-enable-hover\">With your agreement, we and our partners use cookies and data to make the Livestorm website work, as well as for analytics, advertising and marketing purposes. You can enable or disable optional consents as desired. See our </span><a href=\"https://livestorm.co/privacy-policy\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-link-token notion-focusable-token notion-enable-hover\" rel=\"noopener noreferrer\" data-token-index=\"1\" tabindex=\"0\"><span class=\"link-annotation-unknown-block-id--490105158\">Privacy Policy</span></a><span data-token-index=\"2\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-enable-hover\"> for more information.</span><!-- notionvc: fc6b9342-fa99-4cfa-bbc1-8512977df6f0 -->",
      "bannerTitle": "We use cookies and data!",
      "close": "",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies and consents",
      "decline": "Disagree",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "es": {
      "accept": "Aceptar y cerrar",
      "acceptAll": "Aceptar todo",
      "bannerDescription": "Con tu consentimiento, nosotros y nuestros socios utilizamos cookies y datos para que nuestra web funcione, con fines anal&iacute;ticos, publicitarios y marketing. Puedes activar o desactivar las categor&iacute;as opcionales como deseas. Consulta nuestra <a href=\"https://livestorm.co/privacy-policy\" class=\"notion-link-token notion-focusable-token notion-enable-hover\" rel=\"noopener noreferrer\" data-token-index=\"1\" tabindex=\"0\"><span class=\"link-annotation-unknown-block-id--490105158\">Pol&iacute;tica de privacidad</span></a> para obtener m&aacute;s informaci&oacute;n.<!-- notionvc: 574b5410-10fc-45f3-8b9c-88dee018b4f6 -->",
      "bannerTitle": "¡Usamos cookies y datos!",
      "close": "",
      "cookiesFunctional": "Cookies funcionales",
      "cookiesNecessary": "Cookies obligatorias",
      "cookiesOptional": "Cookies y consentimientos opcionales",
      "decline": "Rechazar",
      "declineAll": "Borrar todo",
      "here": "aquí",
      "iframeBlocked": "Para ver esto, por favor habilita las cookies funcionales.",
      "manageCookies": "Más información",
      "save": "Guardar",
      "settingsUnsaved": "Tienes configuraciones no guardadas"
    },
    "fr": {
      "accept": "Accepter et fermer",
      "acceptAll": "Tout accepter",
      "bannerDescription": "Avec votre accord, nos partenaires et nous-m&ecirc;mes utilisons des cookies et donn&eacute;es pour le fonctionnement de notre site web, &agrave; des fins d'analyse, de publicit&eacute; et de marketing. Vous pouvez consentir aux cat&eacute;gories optionnelles de votre choix. Voir notre <a href=\"https://livestorm.co/fr/rgpd\" class=\"notion-link-token notion-focusable-token notion-enable-hover\" rel=\"noopener noreferrer\" data-token-index=\"1\" tabindex=\"0\"><span class=\"link-annotation-unknown-block-id--404024311\">Politique de confidentialit&eacute;</span></a> pour plus d'informations.<!-- notionvc: 2ba2b791-107f-43b5-9d12-02d263043003 -->",
      "bannerTitle": "Nous utilisons des cookies et des données !",
      "close": "",
      "cookiesFunctional": "Cookies fonctionnels",
      "cookiesNecessary": "Cookies obligatoires",
      "cookiesOptional": "Cookies et consentements optionnels",
      "decline": "Refuser",
      "declineAll": "Tout refuser",
      "here": "ici",
      "iframeBlocked": "Pour voir cela, veuillez activer les cookies fonctionnels",
      "manageCookies": "En savoir plus",
      "save": "Sauvegarder",
      "settingsUnsaved": "Vous avez des paramètres non sauvegardés"
    },
    "de": {
      "accept": "Zustimmen und schließen",
      "acceptAll": "Alle akzeptieren",
      "bannerDescription": "<span data-token-index=\"0\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-enable-hover\">Mit Ihrer Zustimmung verwenden wir und unsere Partner Cookies und Daten, um die Livestorm-Website funktionsfähig zu machen sowie für Analysen, Werbung und Marketingzwecke. Sie können optionale Einwilligungen nach Wunsch aktivieren oder deaktivieren. Siehe unsere </span><a href=\"https://livestorm.co/privacy-policy\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-link-token notion-focusable-token notion-enable-hover\" rel=\"noopener noreferrer\" data-token-index=\"1\" tabindex=\"0\"><span class=\"link-annotation-unknown-block-id--490105158\">Datenschutzrichtlinie</span></a><span data-token-index=\"2\" class=\"discussion-level-1 discussion-id-1050c272-0fd5-80dd-aaa4-001c4fdf9918 notion-enable-hover\"> für weitere Informationen.</span><!-- notionvc: fc6b9342-fa99-4cfa-bbc1-8512977df6f0 -->",
      "bannerTitle": "Wir verwenden Cookies und Daten!",
      "close": "",
      "cookiesFunctional": "Funktionale Cookies",
      "cookiesNecessary": "Notwendige Cookies",
      "cookiesOptional": "Optionale Cookies und Zustimmungen",
      "decline": "Nicht einverstanden",
      "declineAll": "Alle ablehnen",
      "here": "hier",
      "iframeBlocked": "Um den Inhalt zu sehen, aktivieren Sie bitte funktionale Cookies",
      "manageCookies": "Erfahren Sie mehr",
      "save": "Speichern",
      "settingsUnsaved": "Es gibt nicht gespeicherte Einstellungen"
    }
  }
} as ModuleOptions