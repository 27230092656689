import validate from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45trailing_45slash_45redirect_45global from "/app/app/middleware/00.trailing-slash-redirect.global.js";
import _01_45i18n_45global from "/app/app/middleware/01.i18n.global.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.0.0_eslint@8.57.0_rollup@4.34.9_typescript@5.7.3_vite@6.2.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45trailing_45slash_45redirect_45global,
  _01_45i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-if-the-agency-partner-exists": () => import("/app/app/middleware/check-if-the-agency-partner-exists.js"),
  "check-if-the-blog-author-exists": () => import("/app/app/middleware/check-if-the-blog-author-exists.js"),
  "check-if-the-blog-category-exists": () => import("/app/app/middleware/check-if-the-blog-category-exists.js"),
  "check-if-the-blog-post-exists": () => import("/app/app/middleware/check-if-the-blog-post-exists.js"),
  "check-if-the-customer-story-exists": () => import("/app/app/middleware/check-if-the-customer-story-exists.js"),
  "check-if-the-guide-exists": () => import("/app/app/middleware/check-if-the-guide-exists.js"),
  "check-if-the-ice-breaker-games-category-or-model-exists": () => import("/app/app/middleware/check-if-the-ice-breaker-games-category-or-model-exists.js"),
  "check-if-the-industry-exists": () => import("/app/app/middleware/check-if-the-industry-exists.js"),
  "check-if-the-integration-exists": () => import("/app/app/middleware/check-if-the-integration-exists.js"),
  "check-if-the-partner-exists": () => import("/app/app/middleware/check-if-the-partner-exists.js"),
  "check-if-the-quiz-exists": () => import("/app/app/middleware/check-if-the-quiz-exists.js"),
  "check-if-the-resource-category-exists": () => import("/app/app/middleware/check-if-the-resource-category-exists.js"),
  "check-if-the-resource-exists": () => import("/app/app/middleware/check-if-the-resource-exists.js"),
  "check-if-the-software-comparison-exists": () => import("/app/app/middleware/check-if-the-software-comparison-exists.js"),
  "check-if-the-subindustry-exists": () => import("/app/app/middleware/check-if-the-subindustry-exists.js"),
  "check-if-the-team-exists": () => import("/app/app/middleware/check-if-the-team-exists.js"),
  "check-if-the-use-case-exists": () => import("/app/app/middleware/check-if-the-use-case-exists.js"),
  "check-if-the-video-glossary-article-exists": () => import("/app/app/middleware/check-if-the-video-glossary-article-exists.js"),
  "check-if-the-virtual-background-category-exists": () => import("/app/app/middleware/check-if-the-virtual-background-category-exists.js"),
  "check-if-the-virtual-background-exists": () => import("/app/app/middleware/check-if-the-virtual-background-exists.js"),
  "check-if-the-virtual-background-platform-exists": () => import("/app/app/middleware/check-if-the-virtual-background-platform-exists.js"),
  "check-if-the-webinar-glossary-article-exists": () => import("/app/app/middleware/check-if-the-webinar-glossary-article-exists.js"),
  "check-if-the-webinar-tool-exists": () => import("/app/app/middleware/check-if-the-webinar-tool-exists.js")
}