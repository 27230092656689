<template>
  <div
    class="select"
    :class="props.theme"
    @click="onClick"
  >
    <UiComponentIcon
      v-if="hasIcon"
      :type="iconType"
      :class="['left', size, iconType]"
      :theme="props.theme"
    />
    <select
      :id="id"
      ref="$el"
      v-model="model"
      :class="[size, { 'has-icon': hasIcon }]"
      :disabled="disabled"
      :required="required"
      tabindex="0"
      @change="onChange"
      @invalid="setErrorMessage"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.length ? option : option.value"
        :disabled="option.disabled"
        :hidden="option.hidden"
        :selected="option === ''"
      >
        {{ option.length ? option : option.name }}
      </option>
    </select>
    <UiComponentIcon
      :theme="props.theme"
      type="chevron-down"
      :class="[{ disabled }, size]"
    />
  </div>
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'select:change',
])

// Props
const props = defineProps({
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  errorMessage: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  hasIcon: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  iconType: {
    default() {
      return 'globe'
    },
    type: String,
  },
  id: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  index: {
    default() {
      return 0
    },
    required: false,
    type: Number,
  },
  options: {
    required: true,
    type: Array,
  },
  required: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['xs', 's', 'm', 'l'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Refs
const $el = ref(null)
const model = ref(
  props.options[props.index].length
    ? props.options[props.index]
    : props.options[props.index].value,
)

// Watchers
watch(() => props.index, (value) => {
  model.value = props.options[value]
})

// Methods
function getIndex() {
  return props.index
}

function onChange() {
  $el.value.setCustomValidity('')
  emit('select:change', model.value)
}

function onClick() {
  $el.value.focus()
  nextTick(() => {
    $el.value.showPicker()
  })
}

function setErrorMessage() {
  if (!props.errorMessage) {
    return
  }
  $el.value.setCustomValidity(props.errorMessage)
}

// Expose
defineExpose({
  $el,
  getIndex,
})
</script>

<style lang="postcss" scoped>
.select {
  @apply relative flex w-full;

  &.dark {
    i {
      @apply text-[#CED0D0];

      &.disabled {
        @apply text-[#CED0D0];
      }
    }

    select {
      @apply bg-[#182427] border-[#5D6669] backdrop-blur-md;

      &:focus {
        @apply border-winter-green-700;
      }

      &:hover {
        @apply border-[#5D6669];
      }
    }
  }

  &.light {
    i {
      @apply text-grey-blue-800;

      &.disabled {
        @apply text-grey-blue-500;
      }
    }

    select {
      @apply bg-transparent border-grey-blue-300;

      &:focus {
        @apply border-livestorm-blue-700;
      }

      &:hover {
        @apply border-grey-blue-600;
      }
    }
  }

  select {
    @apply w-full rounded border truncate appearance-none transition duration-300 ease-out;

    &:disabled {
      @apply opacity-40 cursor-not-allowed !important;
    }

    &:focus {
      @apply outline-none;
    }

    &:hover {
      @apply outline-none cursor-pointer;
    }

    &.xs {
      @apply h-6 px-2 text-xs;
    }

    &.s {
      @apply h-8 px-2 text-sm;
    }

    &.m {
      @apply h-10 pl-3 pr-8 text-sm;
    }

    &.l {
      @apply h-12 pl-4 pr-10 text-origin;
    }

    &.has-icon {
      @apply pl-10;
    }
  }

  i {
    @apply absolute right-4 top-0 flex items-center text-origin not-italic z-10;

    &.xs {
      @apply h-6 text-xs;
    }

    &.s {
      @apply h-8 text-sm;
    }

    &.m {
      @apply h-10 text-sm;
    }

    &.l {
      @apply h-12 text-origin;
    }

    &.left {
      left: 12px;
    }
  }
}
</style>
